@tailwind base;
@tailwind components;
@tailwind utilities;

.highlight {
  text-decoration: underline;
  text-decoration-thickness: 0.5em;
  text-decoration-color: rgba(255, 228, 0, 0.3);
  text-underline-offset: -0.5em;
  text-decoration-skip-ink: none;
}

.border-double {
  border-bottom: double;
}

.text-2xs {
  font-size: 0.65rem;
  line-height: 0.9rem;
}

.text-3xs {
  font-size: 0.5rem;
  line-height: 0.75rem;
}

.w-22 {
  width: 90px;
}

.w-description {
  width: 95%;
}

.w-line {
  width: 35%;
}

.w-call {
  width: 65%;
}

.w-38 {
  width: 150px;
}

.h-34 {
  height: 132px;
}

.mb-item2 {
  margin-bottom: 3px;
}

@keyframes customAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.custom-animation {
  animation: customAnimation 1.5s infinite alternate;
}

.footer-bg {
  background-size: cover;
  background-image: url("./assets/footer-bg.webp");
  width: 100%;
  height: 200px;
}

@media screen and (max-width: 540px) {
  .footer-bg {
    height: 110px;
  }
}

@media screen and (max-width: 480px) {
  .footer-bg {
    height: 100px;
  }
}

@media screen and (max-width: 280px) {
  .footer-bg {
    height: 72px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
